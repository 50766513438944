import React, { InputHTMLAttributes } from 'react'
import clsx from 'clsx'

type Props = {
  icon?: any
  suffix?: string
  inputSuffixPadding?: string
  error?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const FormInput: React.FC<Props> = React.forwardRef<HTMLInputElement, Partial<Props>>(
  (
    {
      icon = undefined,
      suffix = undefined,
      inputSuffixPadding = undefined,
      error = false,
      ...props
    },
    ref
  ) => {
    const Component = icon

    return (
      <div className="relative w-full">
        {icon && (
          <div
            className={clsx(
              'pointer-events-none absolute top-0 left-3 z-10 flex h-9 w-6 items-center justify-center',
              error && 'text-red-500',
              !error && 'text-stone-500'
            )}
          >
            <Component />
          </div>
        )}
        <input
          ref={ref}
          className={clsx(
            props.className,
            'block h-9 w-full border text-sm leading-none shadow focus:outline-none',
            'rounded-lg',
            !props.disabled && [
              'placeholder-stone-500 focus:border-stone-300 focus:ring-2 focus:ring-stone-100',
              error && 'border-red-500 text-red-500 focus:text-stone',
              !error && 'border-stone-200 text-stone',
            ],
            suffix && [icon ? `pl-11 ${inputSuffixPadding}` : `pl-3 ${inputSuffixPadding}`],
            !suffix && [icon ? 'pl-11 pr-3' : 'px-3'],
            props.disabled && 'bg-gray-100 text-gray-500 border-gray-200'
          )}
          {...props}
        />
        {suffix && (
          <div className="pointer-events-none absolute top-0 right-3 flex h-full items-center pb-px text-sm leading-none text-stone-500">
            {suffix}
          </div>
        )}
      </div>
    )
  }
)

FormInput.displayName = 'FormInput'

export default FormInput
