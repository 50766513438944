import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import FormGroup from 'components/ui/FormGroup/FormGroup'
import FormInput from 'components/ui/FormInput/FormInput'
import Button from 'components/ui/Button/Button'
import { emailValidationPattern } from 'utils/formValidations'
import { useUser } from '@supabase/auth-helpers-react'
import { supabaseClient } from '@supabase/auth-helpers-nextjs'
import Link from 'next/link'
import { APP_ROUTES } from 'utils/constants'

export interface SignInParams {
  email: string
  password: string
}

interface Props {
  onSuccess?: () => void
}

const noop = () => {}

const FormSignIn: React.FC<Props> = ({ onSuccess = noop }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<SignInParams & { general: string }>()
  const [isLoading, setIsLoading] = useState(false)
  // const { authCheck, currentUser } = useCurrentUser()
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      onSuccess()
    }
  }, [onSuccess, user])

  const onSubmit: SubmitHandler<SignInParams> = async ({ email, password }) => {
    clearErrors()

    try {
      setIsLoading(true)

      const { user: userResult, error } = await supabaseClient.auth.signIn({
        email,
        password,
      })

      if (error) {
        setError('general', { type: 'sign_in_failed', message: error.message })
        return
      }

      if (!userResult) {
        setError('general', { type: 'sign_in_failed', message: 'General error' })
        return
      }
    } catch (err) {
      setError('general', { type: 'sign_in_failed', message: 'Sign in failed' })
    } finally {
      setIsLoading(false)
    }
  }

  register('general')

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      {errors.general && (
        <div className="rounded bg-red-50 p-3 text-red-500">{errors.general.message}</div>
      )}

      <FormGroup htmlFor="email" label="Email" error={errors.email?.message}>
        <FormInput
          id="email"
          type="text"
          {...register('email', {
            required: 'This field is required',
            pattern: emailValidationPattern,
          })}
        />
      </FormGroup>

      <FormGroup htmlFor="password" label="Password" error={errors.password?.message}>
        <FormInput
          id="password"
          type="password"
          {...register('password', {
            required: 'This field is required',
          })}
        />
      </FormGroup>

      <Button type="submit" className="w-full" loading={isLoading}>
        Log in
      </Button>

      <div>
        <Link href={APP_ROUTES.RESET_PASSWORD}>
          <a>Forgot your password?</a>
        </Link>
      </div>
    </form>
  )
}

export default FormSignIn
