import React, { useEffect } from 'react'

import { useUser } from '@supabase/auth-helpers-react'
import { useRouter } from 'next/router'
import FormSignIn from '@/components/shared/forms/FormSignIn'
import { APP_ROUTES } from 'utils/constants'
import AuthLayout from '@/components/layout/AuthLayout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { Locale } from 'utils/i18n'
import { useTranslation } from 'next-i18next'

interface Props {}

export async function getStaticProps({ locale }: { locale?: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || Locale.En, ['common'])),
    },
  }
}

const Login: React.FC<Props> = () => {
  const { user } = useUser()
  const { push } = useRouter()

  const { t } = useTranslation()

  useEffect(() => {
    if (user) {
      push(APP_ROUTES.HOME)
    }
  }, [user])

  return (
    <AuthLayout>
      <h1 className="pb-9 text-2xl font-semibold">{t('login.title')}</h1>

      <FormSignIn onSuccess={() => push(APP_ROUTES.HOME)} />
    </AuthLayout>
  )
}

export default Login
