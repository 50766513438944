import React from 'react'
import Layout from '@/components/layout/Layout'
import Container from 'components/ui/Container/Container'
import Link from 'next/link'
import { APP_ROUTES } from 'utils/constants'

interface Props {
  children: React.ReactNode
}

const AuthLayout: React.FC<Props> = ({ children }) => (
  <Layout hideHeader>
    <div className="-mt-7 px-4 pb-20 md:px-10">
      <Link href={APP_ROUTES.HOME}>
        <a>
          <div className="w-36">
            <svg viewBox="0 0 143 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M39.8086 24.5594L44.4223 11.2419H48.001L41.7901 27.5848H37.7679L31.5866 11.2419H35.1948L39.8086 24.5594Z"
                fill="#2C4715"
              />
              <path
                d="M64.0064 19.0129C64.0064 19.6259 63.967 20.1796 63.8881 20.6739H51.4368C51.5354 21.979 52.0185 23.027 52.886 23.8179C53.7536 24.6088 54.8183 25.0043 56.0802 25.0043C57.8941 25.0043 59.1757 24.243 59.925 22.7205H63.5628C63.0698 24.2233 62.1727 25.4591 60.8714 26.428C59.5898 27.3771 57.9927 27.8517 56.0802 27.8517C54.5225 27.8517 53.1226 27.5057 51.8805 26.8136C50.658 26.1017 49.6919 25.1131 48.9821 23.8476C48.292 22.5623 47.9469 21.0793 47.9469 19.3985C47.9469 17.7178 48.2821 16.2446 48.9525 14.9791C49.6426 13.6938 50.5988 12.7052 51.8213 12.0131C53.0635 11.321 54.4831 10.975 56.0802 10.975C57.6181 10.975 58.9884 11.3111 60.1912 11.9834C61.3939 12.6557 62.3304 13.6049 63.0008 14.8308C63.6712 16.037 64.0064 17.431 64.0064 19.0129ZM60.4869 17.9452C60.4672 16.6994 60.0236 15.7009 59.156 14.9495C58.2885 14.1981 57.2139 13.8224 55.9323 13.8224C54.769 13.8224 53.7733 14.1981 52.9452 14.9495C52.1171 15.6811 51.6241 16.6796 51.4664 17.9452H60.4869Z"
                fill="#2C4715"
              />
              <path
                d="M74.0676 10.975C75.3492 10.975 76.4928 11.2419 77.4983 11.7758C78.5236 12.3097 79.3222 13.1006 79.894 14.1486C80.4658 15.1966 80.7516 16.4621 80.7516 17.9452V27.5848H77.4096V18.4494C77.4096 16.9861 77.0449 15.8689 76.3153 15.0978C75.5858 14.3068 74.5901 13.9114 73.3282 13.9114C72.0663 13.9114 71.0608 14.3068 70.3115 15.0978C69.582 15.8689 69.2172 16.9861 69.2172 18.4494V27.5848H65.8456V11.2419H69.2172V13.1105C69.7693 12.4382 70.4692 11.9142 71.3171 11.5385C72.1846 11.1628 73.1015 10.975 74.0676 10.975Z"
                fill="#2C4715"
              />
              <path
                d="M87.5071 14.0003V23.0467C87.5071 23.6597 87.6451 24.1046 87.9212 24.3814C88.2169 24.6385 88.7098 24.767 89.3999 24.767H91.4702V27.5848H88.8084C87.2902 27.5848 86.1269 27.2288 85.3185 26.517C84.5101 25.8051 84.1059 24.6484 84.1059 23.0467V14.0003H82.1835V11.2419H84.1059V7.17846H87.5071V11.2419H91.4702V14.0003H87.5071Z"
                fill="#2C4715"
              />
              <path
                d="M107.857 11.2419V27.5848H104.485V25.6568C103.953 26.3291 103.253 26.863 102.386 27.2585C101.538 27.6342 100.631 27.822 99.6647 27.822C98.3831 27.822 97.2297 27.5551 96.2044 27.0212C95.1988 26.4873 94.4003 25.6964 93.8088 24.6484C93.237 23.6004 92.9511 22.3349 92.9511 20.8519V11.2419H96.2931V20.3476C96.2931 21.8109 96.6579 22.938 97.3874 23.7289C98.1169 24.5001 99.1126 24.8857 100.375 24.8857C101.636 24.8857 102.632 24.5001 103.362 23.7289C104.111 22.938 104.485 21.8109 104.485 20.3476V11.2419H107.857Z"
                fill="#2C4715"
              />
              <path
                d="M114.198 13.6147C114.691 12.7843 115.342 12.1416 116.15 11.6868C116.979 11.2123 117.955 10.975 119.078 10.975V14.4749H118.221C116.9 14.4749 115.894 14.811 115.204 15.4833C114.534 16.1556 114.198 17.3223 114.198 18.9833V27.5848H110.827V11.2419H114.198V13.6147Z"
                fill="#2C4715"
              />
              <path
                d="M122.414 9.07673C121.803 9.07673 121.29 8.8691 120.876 8.45386C120.462 8.03861 120.255 7.5245 120.255 6.91152C120.255 6.29854 120.462 5.78443 120.876 5.36919C121.29 4.95394 121.803 4.74632 122.414 4.74632C123.006 4.74632 123.509 4.95394 123.923 5.36919C124.337 5.78443 124.544 6.29854 124.544 6.91152C124.544 7.5245 124.337 8.03861 123.923 8.45386C123.509 8.8691 123.006 9.07673 122.414 9.07673ZM124.07 11.2419V27.5848H120.699V11.2419H124.07Z"
                fill="#2C4715"
              />
              <path
                d="M134.087 27.8517C132.549 27.8517 131.159 27.5057 129.917 26.8136C128.675 26.1017 127.699 25.1131 126.989 23.8476C126.279 22.5623 125.924 21.0793 125.924 19.3985C125.924 17.7375 126.289 16.2644 127.018 14.9791C127.748 13.6938 128.744 12.7052 130.005 12.0131C131.267 11.321 132.677 10.975 134.235 10.975C135.792 10.975 137.202 11.321 138.464 12.0131C139.726 12.7052 140.722 13.6938 141.451 14.9791C142.181 16.2644 142.545 17.7375 142.545 19.3985C142.545 21.0595 142.171 22.5326 141.422 23.8179C140.672 25.1032 139.647 26.1017 138.346 26.8136C137.064 27.5057 135.644 27.8517 134.087 27.8517ZM134.087 24.9153C134.954 24.9153 135.763 24.7077 136.512 24.2925C137.281 23.8772 137.902 23.2543 138.375 22.4239C138.848 21.5934 139.085 20.5849 139.085 19.3985C139.085 18.2121 138.858 17.2135 138.405 16.4028C137.951 15.5723 137.35 14.9495 136.601 14.5342C135.852 14.119 135.043 13.9114 134.176 13.9114C133.308 13.9114 132.5 14.119 131.75 14.5342C131.021 14.9495 130.439 15.5723 130.005 16.4028C129.572 17.2135 129.355 18.2121 129.355 19.3985C129.355 21.1584 129.798 22.5227 130.686 23.4916C131.593 24.4408 132.726 24.9153 134.087 24.9153Z"
                fill="#2C4715"
              />
              <path
                d="M0.604716 15.8052C1.38358 13.4419 6.71416 4.25376 9.44784 0L16.1313 11.4149C16.1313 11.4149 12.8333 11.7076 11.4324 11.7661C8.368 11.8942 5.67011 12.7027 3.47304 15.015C1.78727 16.7891 0.69228 20.42 0.458799 22.0688C0.244773 21.0346 -0.533503 19.259 0.604716 15.8052Z"
                fill="#2C4715"
              />
              <path
                d="M2.20991 18.7321C1.85969 19.7156 1.22734 22.0493 0.954946 23.0932C1.46082 22.4005 2.93371 21.2551 3.84429 20.5761C4.98251 19.7273 7.93022 18.4394 10.0607 17.8833C12.1913 17.3272 15.2849 15.776 16.1605 15.1906C16.8609 14.7223 17.9894 13.5515 18.4661 13.0247C18.4466 13.3662 17.9412 14.3935 17.482 14.9564C16.9566 15.6004 15.1763 16.8589 13.2793 17.8833C11.3822 18.9077 8.46372 19.6102 6.32503 20.7517C4.82594 21.5518 2.78857 23.4614 1.73968 24.4446L1.71376 24.4689C2.06399 24.2445 2.9804 23.6552 3.84429 23.0932C4.92414 22.3908 8.1637 21.8054 10.0607 21.659C11.9578 21.5127 16.9776 21.6298 19.1082 19.0834C20.8126 17.0462 21.2776 11.249 21.2971 8.60508C20.9663 9.26851 20.1063 10.7241 19.3125 11.2393C18.3202 11.8832 17.2987 12.1759 14.2634 12.3808C11.2282 12.5857 8.60965 12.6149 6.32503 13.7564C4.20845 14.8139 2.64769 17.5028 2.20991 18.7321Z"
                fill="#2C4715"
              />
              <path
                d="M11.8702 27.6884C7.53588 28.8023 3.63999 26.6933 2.29747 25.0835C3.52325 24.1469 4.86577 23.306 7.20059 22.8298C8.92252 22.4786 12.9501 22.42 14.9639 22.0981C16.5749 21.8405 18.0867 21.0346 18.6412 20.6639C17.9991 23.6493 16.5399 26.4884 11.8702 27.6884Z"
                fill="#2C4715"
              />
            </svg>
          </div>
        </a>
      </Link>
    </div>

    <Container>
      <div className="mx-auto w-full max-w-lg">{children}</div>
    </Container>
  </Layout>
)

export default AuthLayout
