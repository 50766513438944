import React, { ReactNode } from 'react'
import clsx from 'clsx'

type Props = {
  htmlFor: string
  label?: string
  hideLabel?: boolean
  error?: string | undefined
  children: ReactNode
  className?: string
}

const FormGroup: React.FC<Props> = ({
  htmlFor,
  label = undefined,
  hideLabel = false,
  children,
  error = undefined,
  className = undefined,
}) => (
  <div className={className}>
    {label && (
      <label
        className={clsx(
          'mb-2.5 block text-sm leading-none ',
          error && 'text-red-500',
          !error && 'text-stone',
          hideLabel && 'sr-only'
        )}
        htmlFor={htmlFor}
      >
        {label}
      </label>
    )}
    <div>{children}</div>
    {error && <div className="py-1 text-xs font-medium text-red-500">{error}</div>}
  </div>
)

export default FormGroup
